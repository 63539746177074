
import { defineComponent, onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { formatDateTime } from "@/core/helpers/date";
import Pagination from "@/components/Pagination.vue";
import EventEnvironmentCreateModal from "@/views/admin/master/EventEnvironmentCreateModal.vue";
import EventEnvironmentEditModal from "@/views/admin/master/EventEnvironmentEditModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "AdminEventEnvironments",
  components: {
    Pagination,
    EventEnvironmentCreateModal,
    EventEnvironmentEditModal,
  },
  setup() {
    const store = useStore();

    let search = ref("");
    let currentPageURL = ref("");

    let isLoading = computed(() => {
      return store.getters[Modules.MASTER + "getIsLoading"];
    });
    let eventEnvironments = computed(() => {
      return store.getters[Modules.MASTER + "getEventEnvironments"];
    });
    let eventEnvironmentsMeta = computed(() => {
      return store.getters[Modules.MASTER + "getMeta"];
    });
    let eventEnvironment = ref({});

    // let filteredEventEnvironments = computed(() =>
    //   Object.prototype.hasOwnProperty.call(eventEnvironments.value, "data")
    //     ? eventEnvironments.value.data.filter(
    //         (item) =>
    //           item.name.toUpperCase().includes(search.value.toUpperCase()) ||
    //           item.code.toUpperCase().includes(search.value.toUpperCase()) ||
    //           !search.value
    //       )
    //     : []
    // );

    onMounted(() => {
      setCurrentPageTitle("Event Environments");
    });

    fetchRecords();

    function refresh() {
      fetchRecords();
    }

    function searchNow() {
      currentPageURL.value = "";
      fetchRecords();
    }

    function gotoPage(url) {
      currentPageURL.value = url;
      fetchRecords();
    }

    function fetchRecords() {
      store.dispatch(Modules.MASTER + Actions.FETCH_EVENT_ENVIRONMENTS, {
        search: search.value,
        url: currentPageURL.value,
      });
    }

    return {
      formatDateTime,
      search,
      isLoading,
      eventEnvironments,
      eventEnvironmentsMeta,
      eventEnvironment,
      // filteredEventEnvironments,
      refresh,
      searchNow,
      gotoPage,
    };
  },
});
