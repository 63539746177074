
import { defineComponent, onMounted, computed, ref } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SubmitButton from "@/components/SubmitButton.vue";

export default defineComponent({
  name: "AdminEventEnvironmentCreateModal",
  components: { SubmitButton },
  setup() {
    const store = useStore();
    const router = useRouter();

    let isLoading = computed(() => {
      return store.getters[Modules.MASTER + "getIsLoading"];
    });

    const modalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLButtonElement | null>(null);

    const schema = yup.object({
      name: yup.string().required().max(64).label("Event Environment Name"),
      code: yup.string().required().max(64).label("Event Environment Code"),
      image: yup.string().required().label("Image"),
    });

    const { meta, errors, handleSubmit, isSubmitting, setErrors } = useForm({
      initialValues: {
        name: "",
        code: "",
        image: "",
      },
      validationSchema: schema,
    });

    const { value: name } = useField("name");
    const { value: code } = useField("code");
    const { value: image } = useField("image");

    const onSubmit = handleSubmit((values) => {
      let formData = new FormData();

      // formData.append("_method", "put");
      formData.append("name", values.name!);
      formData.append("code", values.code!);
      formData.append("image", values.image!);

      return new Promise<void>((resolve) => {
        store
          .dispatch(Modules.MASTER + Actions.STORE_EVENT_ENVIRONMENT, formData)
          .then(() => {
            Swal.fire({
              text: store.getters[Modules.MASTER + "getSuccessMessage"],
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              hideModal(modalRef.value);
              store
                .dispatch(Modules.MASTER + Actions.FETCH_EVENT_ENVIRONMENTS, {
                  search: "",
                  url: "",
                })
                .then(() => {
                  name.value = "";
                  code.value = "";
                  image.value = "";
                });
            });
          })
          .catch(() => {
            setErrors(store.getters[Modules.MASTER + "getErrors"]);
            Swal.fire({
              text: "Failed to create the event environment. Please fix the errors.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          })
          .finally(() => {
            resolve();
          });
      });
    });

    function chooseFile(event) {
      if (event.target.files.length === 0) {
        image.value = "";
        return;
      }
      image.value = event.target.files[0];
    }

    return {
      isLoading,
      name,
      code,
      image,

      modalRef,
      submitButton,

      meta,
      errors,
      onSubmit,
      isSubmitting,

      chooseFile,
    };
  },
});
